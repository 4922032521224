<template>
    <div>

    </div>
</template>
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js"></script>
<script>
import Vue from "vue";
import vueCryptojsMin from 'vue-cryptojs'
import Footer from "@/components/Footer";
import lang from "@/mixins/lang";
import { format } from "@/mixins/format";
import {mapState} from "vuex";
import { getNice } from "@/api/nice";
Footer

Vue.use(vueCryptojsMin)

export default {
    //components: { Footer },
    mixins: [format, lang],
    data(){
        return{
          encData : null,
          userInfo : null,
          accountId : null,
        }
    },
    created(){
      this.encData = localStorage.getItem("encData") 
      this.sEncodeData = this.$route.query.EncodeData
    },
    mounted(){
        if(this.$route.query.currentLang != null) {
            this.currentLang = this.$route.query.currentLang;
        }
        this.workerType = this.$route.query.workerType;
        this.device = this.$route.query.device;
        this.accountId = this.$route.params.accountId;
        this.sendEncode()
    },
    computed: {
    ...mapState({
      secretKey : state => state.store.secretKey,
      iv: state => state.store.iv,
    }),
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
      },
    },
  },
  watch : { 

  },
    methods : {
      sendEncode(){
        getNice(this.sEncodeData).then(res => {
          if(res.data.result == 0){
            var result = {sname : res.data.data.sname , smobileNo : res.data.data.smobileNo}
            localStorage.setItem("niceInfo",JSON.stringify(result))
          }
          localStorage.removeItem("encData") 
          window.opener.result()
          window.close();
        })
      },
    },
};
</script>

<style>

</style>